

.pageContainer{
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;



}

@import "https://unpkg.com/open-props" layer(design.system);
@import "https://unpkg.com/open-props/normalize.min.css" layer(demo.support);

@layer demo {
  .rounded-border-gradient {
    inline-size: var(--size-content-1);
    aspect-ratio: var(--ratio-widescreen);
    border: solid var(--size-2) transparent;
    border-radius: var(--radius-4);
    background: 
      linear-gradient(var(--surface-1), var(--surface-1)) padding-box, 
      var(--gradient-1) border-box;
  }
}

@layer demo.support {
  .body {

    padding: var(--size-5);
    gap: var(--size-5);
  } 
}